import { FIAT_RATE_DECIMAL_PRECISION } from 'modules/helpers/currencies'

export const THOUSANDS_SEPARATOR = ','
export const DECIMAL_SEPARATOR = '.'

export const addSeparatorToNumber = (value: number | string): string => {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR)
}
/**
 * calc precision https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
 *
 * @precision - use useCurrencies().getDecimalPrecision() - count decimal place
 */
export const roundPrecision = (value: number | undefined | '', precision: number): typeof value => {
	// here we need full number precision not only count decimal places
	const calcPrecision = parseFloat('1e' + precision)
	return value
		? parseFloat((Math.round((parseFloat(value.toString()) + Number.EPSILON) * calcPrecision) / calcPrecision).toString())
		: value
}

/**
 * Change string to number | undefined
 * - remove whitespaces & THOUSANDS_SEPARATOR
 */
export const parseStringToFloat = (a?: string): number | undefined => {
	const separator = new RegExp(THOUSANDS_SEPARATOR, 'g')
	return (a ? parseFloat(`${a}`.replace(/\s/g, '').replace(separator, '')) : undefined) || undefined
}

/**
 * return string with formatted numbers as price
 *
 * its duplicate of <StatisticPricePrecision> component
 * use this only when you can't use component
 */
export const getPriceFormatted = (value: number | undefined, precision: number, suffix?: string, showDecimal = true): string => {
	if (typeof value !== 'number') {
		return ''
	}
	// toFixed because number could be short eg:8e-7 (scientific notation)
	const v = value.toFixed(precision)

	const [integer, decimal] = v.split(DECIMAL_SEPARATOR)
	const suffixPart = suffix ? ' ' + suffix : ''
	const decimalPart = showDecimal ? DECIMAL_SEPARATOR + decimal : ''

	return addSeparatorToNumber(integer) + decimalPart + suffixPart
}

export const getFiatRateFormatted = (v: number | undefined, suffix = 'EUR'): string | typeof v =>
	getPriceFormatted(v, FIAT_RATE_DECIMAL_PRECISION, suffix)
