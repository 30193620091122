import Text from 'antd/es/typography/Text'
import { useUserData } from 'hooks/useUserData'
import { MobileView } from 'modules/styles/mixins/MobileView'
import QueueAnim from 'rc-queue-anim'
import { FC, memo } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
	white-space: nowrap;
	margin-left: 30px;

	${MobileView(
		css`
			display: none;
		`,
		{ screen: 'midContent' },
	)}
`
const NameStyled = styled(Text)`
	width: 200px;
`
const Row = styled.div`
	height: 20px;
`

export const ClientInfo: FC = memo(() => {
	const { userName, companyName, isPending } = useUserData()

	return (
		<Wrapper>
			{isPending && !companyName && !userName ? null : (
				<QueueAnim type={'alpha'}>
					<Row key={'company'}>
						{companyName ? (
							<>
								<Text strong>Company account: </Text>
								<NameStyled ellipsis={{ tooltip: companyName }}>{companyName}</NameStyled>
							</>
						) : null}
					</Row>
					<Row key={'private'}>
						{userName ? (
							<>
								<Text strong>Welcome: </Text>
								<NameStyled ellipsis={{ tooltip: userName }}>{userName}</NameStyled>
							</>
						) : null}
					</Row>
				</QueueAnim>
			)}
		</Wrapper>
	)
})
