import { Space } from 'antd'
import { InflowAmountCell } from 'components/functional/trade/common/InflowAmountCell'
import { CancelAction } from 'components/functional/trade/tradesInProgress/CancelAction'
import { ExpandedRow } from 'components/functional/trade/tradesInProgress/ExpandedRow'
import { ITradeRecord } from 'components/functional/trade/tradesInProgress/useTradeInProgressData'
import { DefaultTableStyles, RemoveBorderExpandedRow } from 'components/ui/DefaultTableStyles'
import { useSelector } from 'hooks/useSelector'
import { ACCEPTED_CURRENCY } from 'modules/helpers/currencies'
import { themeColor } from 'modules/styles/themeStyles'
import { Table } from 'modules/table/Table'
import { useExpandRowTable } from 'modules/table/useExpandRowTable'
import { renderDateWithTime, useSetTableColumn } from 'modules/table/useSetTableColumn'
import { ColoredCurrency } from 'modules/ui/ColoredCurrency'
import { EllipsisText } from 'modules/ui/EllipsisText'
import { FC } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	${DefaultTableStyles};
	${RemoveBorderExpandedRow};

	// .ant-table-row:nth-child(2n) {
	// 	&,
	// 	& + .ant-table-expanded-row td {
	// 		background: ${themeColor.blueWhite}!important;
	// 	}
	// }
	// hover on rows
	.ant-table-row:hover {
		&,
      // expanded row of parent
    &.ant-table-row-level-0 + .ant-table-expanded-row {
			td {
				background: ${themeColor.blueWhite}!important;
			}
		}
	}
`

export const TradesInProgress: FC<{ data: ITradeRecord[] }> = ({ data }) => {
	const { ids, list } = useSelector((state) => state.trades)
	const expandDetails = useExpandRowTable()

	const tableColumn = useSetTableColumn<ITradeRecord>({
		referenceNumber: {
			title: 'Order id',
			render: (v) => <EllipsisText width={150}>{v}</EllipsisText>,
			width: 150,
		},
		startAt: {
			title: 'Start date',
			render: renderDateWithTime,
		},
		inflow: {
			title: 'Type and amount',
			render: (v, r) => <InflowAmountCell item={ids[r.id]} />,
			width: 160,
			colSpan: 3,
		},
		amountSeparator: {
			width: 20,
			colSpan: 0,
		},
		outflow: {
			render: (v, r) => <ColoredCurrency currency={ids[r.id]?.outflow?.currency as ACCEPTED_CURRENCY} />,
			width: 160,
			colSpan: 0,
		},
		actions: {
			render: (v, r) => (
				<Space size="middle">
					<CancelAction item={ids[r.id]} />
				</Space>
			),
			width: 110,
		},
	})

	return (
		<Wrapper>
			<Table<ITradeRecord>
				columns={tableColumn}
				dataSource={data}
				rowKey={(record) => record.id}
				pagination={false}
				size={'large'}
				rowClassName={'with-expanded'}
				expandable={{
					expandIconColumnIndex: -1,
					expandedRowKeys: list,
					expandedRowRender: (record) => <ExpandedRow item={ids[record.id]} expandDetails={expandDetails} />,
				}}
			/>
		</Wrapper>
	)
}

export default TradesInProgress
