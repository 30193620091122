import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/kyc/api'
import { Components } from 'api/kyc/generated/client'
import { AsyncThunkActionType } from 'store/store'

type IndividualProfile = Components.Schemas.IndividualProfile

export const individualProfileGet: AsyncThunkActionType<IndividualProfile> = createAsyncThunk(
	'individualProfileGet',
	async (data, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			const response = await api.GetIndividualProfile(undefined, null, data?.config)

			return response.data
		} catch (err: any) {
			return rejectWithValue(err?.response?.data)
		}
	},
)
