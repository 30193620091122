import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Paths } from 'api/fx/generated/client'
import { AsyncThunkDataType } from 'store/common'
import { TradeResponse } from 'store/slices/tradesSlice'
import { AsyncThunkActionType } from 'store/store'

type PathParameters = Paths.TradeFrontofficeControllerCancelTrade.PathParameters

export const tradeCancel: AsyncThunkActionType<TradeResponse[], AsyncThunkDataType<PathParameters, null, 'params'>> = createAsyncThunk(
	'tradeCancel',
	async (data, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			const response = await api.TradeFrontofficeController_cancelTrade(data.params, null, data?.config)

			return response.data as TradeResponse[]
		} catch (err: any) {
			return rejectWithValue(err.response?.data?.message)
		}
	},
)
