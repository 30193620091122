import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components, Paths } from 'api/fx/generated/client'
import { walletsGet } from 'store/asyncThunks/walletsGet'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType, dispatch } from 'store/store'

type UpdateWalletRequest = Components.Schemas.UpdateWalletRequest
type WalletResponse = Components.Schemas.WalletAddressResponse
type PathParameters = Paths.WalletFrontofficeControllerUpdateWalletIfNewFieldsSubmitted.PathParameters

export const walletUpdate: AsyncThunkActionType<
	WalletResponse,
	AsyncThunkDataType<PathParameters, UpdateWalletRequest, 'params' | 'data'>
> = createAsyncThunk('walletUpdate', async ({ params, data, config }, thunkAPI) => {
	const { rejectWithValue } = thunkAPI

	try {
		const response = await api.WalletFrontofficeController_updateWalletIfNewFieldsSubmitted(params, data, config)

		dispatch(walletsGet({ config: { forceUpdate: true } }))

		return response.data as WalletResponse
	} catch (err: any) {
		return rejectWithValue(err.response?.data?.message)
	}
})
