import { Tooltip } from 'antd'
import { ColumnType } from 'antd/es/table'
import { ColumnTitle } from 'antd/es/table/interface'
import dayjs from 'dayjs'
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'modules/helpers/time'
import { EllipsisText } from 'modules/ui/EllipsisText'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

/**
 * handle ColumnType for every column
 */
export type ExtendedColumnType<RecordType> = {
	[k in keyof RecordType]?: ColumnType<RecordType>
}
type Config<RecordType> = {
	defaultWidth?: ColumnType<RecordType>['width']
	getTitle?: (columnName: keyof RecordType | string) => ColumnTitle<RecordType>
	// set controlledFilters true if you use useControlledFilters on columns, it prevents showing ant error '`FilteredKeys` should all be controlled or not controlled'
	controlledFilters?: boolean
}

export const useSetTableColumn = <RecordType,>(
	columns: ExtendedColumnType<RecordType>,
	config?: Config<RecordType>,
): ColumnType<RecordType>[] => {
	return Object.keys(columns).map((columnName) => {
		const item: ColumnType<RecordType> = columns[columnName]
		const identifier = `${columnName}`

		const result = {
			dataIndex: identifier,
			key: identifier,
			className: identifier,
			width: config?.defaultWidth,
			title: config?.getTitle ? config.getTitle(columnName) : '',
			...item,
		}
		if (config?.controlledFilters) {
			result['filteredValue'] = result['filteredValue'] || null
		}

		return result
	})
}

export type RenderFn<RecordType = object> = (value: ReactNode, record: RecordType) => ReactNode
type RenderLinkType = (getPath: (v, r) => string, getValue?: (v, r) => ReactNode, ellipsis?: number, title?: string) => RenderFn

export const renderDate =
	(format: string = DATE_FORMAT): RenderFn =>
	(value) =>
		value ? <Tooltip title={dayjs(value as number).format(DATE_TIME_FORMAT)}>{dayjs(value as number).format(format)}</Tooltip> : ''
export const renderDateWithTime: RenderFn = (value) => {
	return value ? <span style={{ whiteSpace: 'nowrap' }}>{dayjs(value as number).format(DATE_TIME_FORMAT)}</span> : ''
}

export const renderLink: RenderLinkType = (getPath, getValue, ellipsis, title) => (value, record) => {
	const to = getPath(value, record)
	const v = getValue ? getValue(value, record) : value

	if (to) {
		if (ellipsis) {
			return (
				<Tooltip title={title}>
					<EllipsisText width={ellipsis}>
						<Link to={to}>{v}</Link>
					</EllipsisText>
				</Tooltip>
			)
		}
		return <Link to={to}>{v}</Link>
	}
	return null
}
