import { setInternetConnection } from 'store/slices/deviceSlice'
import { dispatch } from 'store/store'

export const internetConnectionListener = (): void => {
	window.addEventListener('online', () => {
		dispatch(setInternetConnection(true))
	})

	window.addEventListener('offline', () => {
		dispatch(setInternetConnection(false))
	})
}
