import apiSchema from 'api/fx/generated/api.json'
import { Client } from 'api/fx/generated/client'
import { setInterceptors } from 'api/fx/interceptors'
import axios, { AxiosAdapter } from 'axios'
import { Cache, cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import { ENV } from 'config/env'
import { OpenAPIClientAxios } from 'openapi-client-axios'

export let api: Client

export const initFxApi = (): Promise<void> => {
	const openAPIClientAxios = new OpenAPIClientAxios({
		// @ts-ignore
		definition: apiSchema,
		strict: true,
		timeout: 20000,
		validate: true,
		withServer: { url: ENV.FX_API_URL, description: 'FX API' },
		axiosConfigDefaults: {
			headers: {
				'Content-Type': 'application/json',
			},
			adapter: throttleAdapterEnhancer(
				cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { defaultCache: new Cache({ max: 100, maxAge: 1000 * 60 }) }),
				{ threshold: 2 * 1000 },
			),
		},
	})

	return openAPIClientAxios.init<Client>().then((client) => {
		setInterceptors(openAPIClientAxios.client)

		api = client

		return Promise.resolve()
	})
}
