import { createSlice } from '@reduxjs/toolkit'
import { Components } from 'api/fx/generated/client'
import { listToEntity } from 'modules/helpers/listToEntity'
import { accountGet } from 'store/asyncThunks/accountGet'
import { agreementTocAccept } from 'store/asyncThunks/agreementTocAccept'
import { agreementTocGet } from 'store/asyncThunks/agreementTocGet'
import { profilesGet } from 'store/asyncThunks/profilesGet'
import { CaseReducerType } from 'store/store'
import { ObjectKeyType } from 'types'

type ProfileResponse = Components.Schemas.ProfileFrontofficeResponse
type AccountFrontofficeResponse = Components.Schemas.AccountFrontofficeResponse

type State = {
	account?: AccountFrontofficeResponse
	profileId: string
	isForbidden: boolean
	agreements: {
		isTocAccepted?: boolean
		// user can hide init modal
		showTocModal: boolean
	}
	list: string[]
	ids: ObjectKeyType<ProfileResponse>
}

const initialState: State = {
	account: undefined,
	profileId: '',
	isForbidden: false,
	agreements: {
		showTocModal: true,
	},
	list: [],
	ids: {},
}
type CaseReducers<State> = {
	clearAccountState: CaseReducerType<State>
	toggleTocModal: CaseReducerType<State, boolean>
	setForbidden: CaseReducerType<State, boolean>
}

export const accountSlice = createSlice<State, CaseReducers<State>>({
	name: 'account',
	initialState,
	reducers: {
		clearAccountState: () => ({ ...initialState }),
		toggleTocModal: (state, { payload }) => {
			state.agreements.showTocModal = payload
		},
		setForbidden: (state, { payload }) => {
			state.isForbidden = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(accountGet.fulfilled, (state, action) => {
			state.account = action.payload
		})
		builder.addCase(profilesGet.fulfilled, (state, action) => {
			const { list, ids } = listToEntity<ProfileResponse>([action.payload], 'id')
			state.list = list
			state.ids = ids
			// logged profile id
			state.profileId = list[0]
		})
		builder.addCase(agreementTocGet.fulfilled, (state, action) => {
			state.agreements.isTocAccepted = action.payload.latestAccepted
		})
		builder.addCase(agreementTocAccept.fulfilled, (state, action) => {
			state.agreements.isTocAccepted = action.payload.latestAccepted
		})
	},
})

export const { clearAccountState, toggleTocModal, setForbidden } = accountSlice.actions
export default accountSlice.reducer
