import { lazyComponent } from 'modules/helpers/lazyComponent'
import { themeColor, themeSize } from 'modules/styles/themeStyles'
import { FC } from 'react'
import { TradeResponse } from 'store/slices/tradesSlice'
import styled from 'styled-components'

const TradeDetailsContent = lazyComponent(() => import('components/functional/trade/tradeDetails/TradeDetailsContent'))

const Wrapper = styled.div`
	font-size: ${themeSize.lg};
	color: ${themeColor.black};
	margin: 50px 0;
`

export const TradeDetails: FC<{ item: TradeResponse }> = ({ item }) => {
	return (
		<Wrapper>
			<TradeDetailsContent item={item} />
		</Wrapper>
	)
}
