import { AsyncThunkFulfilledActionCreator, AsyncThunkRejectedActionCreator } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { message } from 'antd'

export const UPDATED_MESSAGE = 'The data has been updated'
export const ERROR_MESSAGE = 'An error has occurred. Please try again.'

type AsyncActionType =
	| Promise<ReturnType<AsyncThunkFulfilledActionCreator<any, any>> | ReturnType<AsyncThunkRejectedActionCreator<any, any>>>
	| any

/**
 * Show success or error message based on dispatch thunk returned status
 */
export const messageAsyncThunk = (action: AsyncActionType, msg: string[] = [UPDATED_MESSAGE, ERROR_MESSAGE]): typeof action => {
	action.unwrap &&
		action
			.unwrap()
			.then(() => {
				return msg[0] && message.success(msg[0])
			})
			.catch(() => {
				return msg[1] && message.error(msg[1])
			})
	return action
}
