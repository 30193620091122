import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components } from 'api/fx/generated/client'
import { bankAccountsGet } from 'store/asyncThunks/bankAccountsGet'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType, dispatch } from 'store/store'

type BankAccountResponse = Components.Schemas.BankAccountResponse
type CreateBankAccountRequest = Components.Schemas.CreateBankAccountRequest

export const bankAccountCreate: AsyncThunkActionType<
	BankAccountResponse,
	AsyncThunkDataType<null, CreateBankAccountRequest, 'data'>
> = createAsyncThunk('bankAccountCreate', async ({ data, config }, thunkAPI) => {
	const { rejectWithValue } = thunkAPI

	try {
		const response = await api.BankAccountFrontofficeController_createBankAccount(undefined, data, config)

		dispatch(bankAccountsGet({ config: { forceUpdate: true } }))

		return response.data as BankAccountResponse
	} catch (err: any) {
		return rejectWithValue(err.response?.data?.message)
	}
})
