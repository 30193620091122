import { HEADER_HEIGHT } from 'components/layout/header/common'
import styled, { css } from 'styled-components'

export const Sticky = styled.div<{ sticky?: boolean }>`
	${({ sticky }) =>
		sticky &&
		css`
			position: sticky;
			top: ${HEADER_HEIGHT};
			z-index: 99;
			background-color: #fff;
		`}
`

/**
 * For popovers inside sticky element, use special class name and trigger parent in ConfigProvider
 * popoverElement.parentElement has to be 'position: relative'
 */
export const STICKY_CLASS_NAME = 'sticky-box'

export const isStickyParent = (el?: HTMLElement | null): boolean => {
	const body = document.body

	if (el === body || !el) {
		return false
	}
	if ((el?.getAttribute('class') || '').includes(STICKY_CLASS_NAME)) {
		return true
	}
	return isStickyParent(el.parentElement)
}
