import { Components } from 'api/fx/generated/client'
import { useCurrencies } from 'modules/hooks/useCurrencies'
import { themeWeight } from 'modules/styles/themeStyles'
import { FC } from 'react'
import styled from 'styled-components'

type CurrencyOrCurrencyType = Components.Schemas.CurrencyOrCurrencyType

const Box = styled.span<{ color: string }>`
	font-weight: ${themeWeight.extraBold};
	white-space: nowrap;
	color: ${({ color }) => color};
`

interface IProps {
	currency?: CurrencyOrCurrencyType
	showFullName?: boolean
	color?: string
}
export const ColoredCurrency: FC<IProps> = ({ currency, showFullName, color }) => {
	const { getCurrencyColor, getSymbolName, getFullName } = useCurrencies()
	const destColor = color || getCurrencyColor(currency)
	const text = showFullName ? `${getFullName(currency)} ` : getSymbolName(currency)

	return (
		<Box color={destColor} className={'colored-currency'}>
			{text}
		</Box>
	)
}
