import { themeColor } from 'modules/styles/themeStyles'
import styled from 'styled-components'

/**
 * Styled link, with underline effect
 */
export const FooterLink = styled.a`
	@property --offset {
		syntax: '<length>';
		inherits: false;
		initial-value: 0;
	}

	position: relative;
	display: inline-block;
	color: ${themeColor.black};
	text-underline-offset: var(--offset, 0);
	text-decoration: underline 2px rgba(0, 255, 255, 0) !important;
	transition: color ease 0.3s, --offset 0.3s, text-decoration-color 0.3s;
	cursor: pointer;

	&.active,
	&:hover {
		color: ${themeColor.blue};
		--offset: 0.3em;
		text-decoration-color: ${themeColor.blue}!important;
	}
`
