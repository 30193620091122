import dayjs from 'dayjs'
import { getLandingUrl } from 'utils/getLandingUrl'

interface ILinks {
	label: string
	href: string
}
interface IData {
	links: ILinks[]
	bfx: ILinks[]
	desc: string
	copy: string
}

export const footerData: IData = {
	bfx: [
		{
			label: 'Exchange',
			href: getLandingUrl('exchange'),
		},
		{
			label: 'How it works',
			href: getLandingUrl('exchange', 'how-it-works'),
		},
		{
			label: 'Glossary',
			href: getLandingUrl('exchange/glossary'),
		},
		{
			label: 'Affiliate',
			href: getLandingUrl('exchange/affiliate'),
		},
	],
	links: [
		{
			label: 'About us',
			href: getLandingUrl('about-us'),
		},
		{
			label: 'Contact',
			href: getLandingUrl('contact'),
		},
		{
			label: 'Legal info',
			href: getLandingUrl('legal-info'),
		},
		{
			label: 'Impressum',
			href: getLandingUrl('impressum'),
		},
		{
			label: 'Terms & Conditions',
			href: getLandingUrl('terms-conditions'),
		},
		{
			label: 'Privacy policy',
			href: getLandingUrl('privacy-policy'),
		},
		{
			label: 'FAQ',
			href: getLandingUrl('faq'),
		},
	],
	desc: 'Bitclear Payment Gate and Bitclear Exchange are owned by <strong>Bitclear AG.</strong>',
	copy: `Copyright © ${dayjs().year()} Bitclear.li`,
}
