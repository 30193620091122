import { Modal } from 'antd'
import { useUserData } from 'hooks/useUserData'
import { useUserTocAgreements } from 'hooks/useUserTocAgreements'
import { lazyComponent } from 'modules/helpers/lazyComponent'
import { useModalFn } from 'modules/hooks/useModalFn'
import { FC, useEffect } from 'react'
import { toggleTocModal } from 'store/slices/accountSlice'
import { dispatch } from 'store/store'

const AcceptTocAgreementModalContent = lazyComponent(
	() => import('components/functional/account/agreements/AcceptTocAgreementModalContent'),
)

export const AcceptTocAgreementModal: FC = () => {
	const { account } = useUserData()
	const { showModal, closeModal, openModal } = useModalFn()
	const { isTocAccepted, showTocModal, isPending } = useUserTocAgreements()

	useEffect(() => {
		// account?.kycProfile?.externalId - check it to not show <InitKyc /> modal in the same time
		if (!isPending && !isTocAccepted && showTocModal && account?.kycProfile?.externalId) {
			openModal()
		} else {
			showModal && closeModal()
		}
	}, [account?.kycProfile?.externalId, closeModal, isPending, openModal, showModal, showTocModal, isTocAccepted])

	const onCancelClick = () => {
		dispatch(toggleTocModal(false))
	}

	return showModal ? (
		<>
			<Modal footer={null} width={600} open={showModal} onCancel={onCancelClick} title={'A new version of the documents'}>
				<AcceptTocAgreementModalContent />
			</Modal>
		</>
	) : null
}
