import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components } from 'api/fx/generated/client'
import { AsyncThunkActionType } from 'store/store'

type ProfileResponse = Components.Schemas.ProfileFrontofficeResponse

export const profilesGet: AsyncThunkActionType<ProfileResponse> = createAsyncThunk('profilesGet', async (data, thunkAPI) => {
	const { rejectWithValue } = thunkAPI

	try {
		const response = await api.AccountFrontofficeController_findProfile(undefined, null, data?.config)

		return response.data as ProfileResponse
	} catch (err: any) {
		return rejectWithValue(err.response?.data?.message)
	}
})
