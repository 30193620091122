/**
 * tags used by FX and Cypress to identify HTMLElements
 *
 * usage fx: <div data-cy={cyTag.xxx} />
 *
 * !! some values are used to present text for client - do not change them to random value !!
 */

export const cyTag = {
	breadcrumb: 'breadcrumb', // breadcrumb wrapper

	// dashboard
	newOrderBtn: 'newOrderBtn', // btn buy/sell in new order box

	agreements: {
		acceptCheckbox: 'agreementsAcceptCheckbox',
		acceptBtn: 'agreementsAcceptBtn',
	},

	newOrderForm: {
		youPayInput: 'youPayInput',
		youGetInput: 'youGetInput',
		yourWalletSelect: 'yourWalletSelect',
		yourBankSelect: 'yourBankSelect',
		confirmBtn: 'confirmBtn',
	},

	menu: {
		wallets: 'wallets',
		bankAccounts: 'bankAccounts',
		history: 'history',
	},

	exchangeBoxTabs: {
		wrapper: 'wrapper',
		buyText: 'Buy', // ! do not change the value
		sellText: 'Sell', // ! do not change the value
	},

	wallets: {
		form: {
			editBtn: 'editBtn',
			memoInput: 'memoInput',
			addressInput: 'addressInput',
			saveBtn: 'saveBtn',
		},
	},

	bankAccounts: {
		form: {
			editBtn: 'editBtn',
			ownerInput: 'ownerInput',
			memoInput: 'memoInput',
			ibanInput: 'ibanInput',
			currencyInput: 'currencyInput',
			bankNameInput: 'bankNameInput',
			bicInput: 'bicInput',
			ownerAddressInput: 'ownerAddressInput',
			saveBtn: 'saveBtn',
		},
	},

	ordersInProgress: {
		cancelBtn: 'cancelBtn',
		cancelConfirmationModalWrapper: 'cancelConfirmationModalWrapper',
		cancelConfirmationModalBtnText: 'Cancel the trade', // ! do not change the value
		noOrdersWrapper: 'noOrdersWrapper',
	},

	order: {
		orderDetailsLink: 'orderDetailsLink',
		downloadStatementBtn: 'downloadStatementBtn',
		downloadStatementErrorBox: 'downloadStatementErrorBox',
		referenceNumber: 'referenceNumber',
	},
}
