import { DownOutlined, HomeOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import Text from 'antd/es/typography/Text'
import { KycMenuItem } from 'components/layout/header/menu/KycMenuItem'
import { MenuTradesInProgressItem } from 'components/layout/header/menu/MenuTradesInProgressItem'
import { ACCOUNT_PATH } from 'components/pages/fx/account/AccountPaths'
import { TRADE_PATH } from 'components/pages/fx/trade/TradeRoutes'
import { PATH } from 'components/pages/Paths'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { cyTag } from 'utils/cyTag'

export const Center = styled.div`
	display: inline-flex;
	align-items: center;

	> *:last-child {
		margin-left: 10px;
	}
`

export const menuItems = (mobile?: boolean): ItemType[] => [
	{
		key: 'home',
		label: (
			<Popover content={mobile ? null : <Text strong>Go to dashboard</Text>}>
				<Link to={PATH.DASHBOARD.path}>
					<Center>
						<HomeOutlined style={{ fontSize: '1.4em' }} />
						{mobile ? <span>Dashboard</span> : ''}
					</Center>
				</Link>
			</Popover>
		),
	},
	{
		key: 'exchange-submenu',
		label: (
			<>
				Your exchange &nbsp;
				{mobile ? null : <DownOutlined />}
			</>
		),
		children: [
			{
				key: 'new-order',
				label: <Link to={TRADE_PATH.NEW_TRADE.path}>New order</Link>,
			},
			{
				key: 'trades-in-progress',
				label: <MenuTradesInProgressItem />,
			},
			{
				key: 'orders-history',
				label: (
					<Link to={TRADE_PATH.HISTORY.path} data-cy={cyTag.menu.history}>
						History
					</Link>
				),
			},
		],
	},
	{
		key: 'account-submenu',
		label: (
			<>
				Account and data &nbsp;
				{mobile ? null : <DownOutlined />}
			</>
		),
		children: [
			{ key: 'kyc', label: <KycMenuItem /> },
			{
				key: 'wallets',
				label: (
					<Link to={ACCOUNT_PATH.WALLETS.path} data-cy={cyTag.menu.wallets}>
						Your wallets
					</Link>
				),
			},
			{
				key: 'bank-accounts',
				label: (
					<Link to={ACCOUNT_PATH.BANK_ACCOUNTS.path} data-cy={cyTag.menu.bankAccounts}>
						Your bank accounts
					</Link>
				),
			},
		],
	},
]
