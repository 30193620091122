import { Button } from 'antd'
import { ENV } from 'config/env'
import { useSelector } from 'hooks/useSelector'
import { adminStatuses, CLIENT_STATUS, clientStatuses, KYC_STATUS } from 'modules/helpers/clientStatuses/clientStatuses'
import { FC } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	.ant-btn {
		min-width: 100px;
	}
`

const getKycStatusText = (status?: CLIENT_STATUS): string => {
	if (status) {
		if (clientStatuses.includes(status)) {
			return 'please complete the KYC process first'
		} else if (adminStatuses.includes(status)) {
			return 'please wait for review your KYC'
		}
	}
	return 'please check KYC process first'
}

export interface IKycStatusContentProps {
	title?: string
}
export const KycStatusBannerContent: FC<IKycStatusContentProps> = ({ title = 'If you want to start trading' }) => {
	const { account } = useSelector((state) => state.account)
	const kycSubtitle = getKycStatusText(account?.kycProfile?.status as KYC_STATUS)

	return (
		<Wrapper>
			<div>
				{title}, {kycSubtitle}
			</div>
			<br />
			<a href={ENV.KYC_STANDALONE_URL} title={'Go to KYC'} target={'_blank'} rel={'noreferrer'}>
				<Button type={'primary'}>Go to KYC</Button>
			</a>
		</Wrapper>
	)
}
