import { ThemeConfig } from 'antd/es/config-provider/context'
import { generateColorPalettes } from 'antd/es/theme/themes/default/colors'
import { themeColor, themeWeight } from 'modules/styles/themeStyles'

const linkHoverColor = generateColorPalettes(themeColor.blue)[5]

export const themeFx: ThemeConfig = {
	token: {
		fontFamily:
			"Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
		colorPrimary: themeColor.blue,
		colorBgLayout: '#fff',
		colorLink: 'inherit',
		colorLinkHover: linkHoverColor,
		colorLinkActive: linkHoverColor,
		colorSuccess: themeColor.green,
		fontSizeSM: 12,
		fontSizeHeading5: 18,
		fontWeightStrong: themeWeight.extraBold,
		borderRadius: 4,
	},
	components: {
		Layout: {
			colorBgBody: '#fff',
			colorBgHeader: '#fff',
			fontSizeHeading5: 18,
		},
		Button: {
			// not working vv
			fontWeightStrong: themeWeight.extraBold,
			fontSizeSM: 12,
		},
		Table: {
			colorTextHeading: themeColor.grayDarker,
		},
		Skeleton: {
			// @ts-ignore // one row paragraph should be vertical middle (remove margin)
			margin: 'auto',
		},
		Breadcrumb: {
			linkHoverColor: linkHoverColor,
			colorBgTextHover: 'transparent',
		},
		Menu: {
			colorBgTextHover: 'transparent',
			itemHoverColor: linkHoverColor,
		},
	},
}
