import { createSlice } from '@reduxjs/toolkit'
import { Components } from 'api/fx/generated/client'
import { listToEntity } from 'modules/helpers/listToEntity'
import { bankAccountCreate } from 'store/asyncThunks/bankAccountCreate'
import { bankAccountsGet } from 'store/asyncThunks/bankAccountsGet'
import { bankAccountUpdate } from 'store/asyncThunks/bankAccountUpdate'
import { ObjectKeyType } from 'types'

type BankAccountResponse = Components.Schemas.BankAccountResponse

type State = {
	list: string[]
	ids: ObjectKeyType<BankAccountResponse>
}

const initialState: State = {
	list: [],
	ids: {},
}

export const bankAccountsSlice = createSlice<State, {}>({
	name: 'bankAccounts',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(bankAccountCreate.fulfilled, (state, action) => {
			state.list.push(action.payload.id)
			state.ids[action.payload.id] = action.payload
		})
		builder.addCase(bankAccountsGet.fulfilled, (state, action) => {
			const { ids, list } = listToEntity<BankAccountResponse>(action.payload, 'id')
			state.list = list
			state.ids = ids
		})
		builder.addCase(bankAccountUpdate.fulfilled, (state, action) => {
			state.ids[action.payload.id] = action.payload
		})
	},
})

export const {} = bankAccountsSlice.actions
export default bankAccountsSlice.reducer
