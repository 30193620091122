import { Progress } from 'antd'
import { Components } from 'api/fx/generated/client'
import { MobileView } from 'modules/styles/mixins/MobileView'
import { themeSize } from 'modules/styles/themeStyles'
import { FC, memo } from 'react'
import styled, { css } from 'styled-components'

type TradeStatus = Components.Schemas.TradeStatus

const Wrapper = styled.div`
	padding: 0 10px 0 0;

	.ant-progress,
	.ant-progress-text {
		color: inherit;
	}
	// steps
	.ant-progress-steps-item {
		width: 80px !important;

		${MobileView(css`
			width: 40px !important;
		`)}

		&:first-child {
			border-radius: 4px 0 0 4px;
		}
		&:nth-child(4) {
			border-radius: 0 4px 4px 0;
		}
	}
`
const ProgressText = styled.div`
	font-size: 0.9em;
`
const Step = styled.div`
	font-size: ${themeSize.base};
`

const stepMap: { [k in TradeStatus]: { step: number; desc: string; warning?: boolean } | null } = {
	NEW: { step: 1, desc: 'Order started, we are waiting for funds to be raised.' },
	INFLOW_RECEIVED: { step: 2, desc: 'Funds received' },
	EXECUTION_REQUESTED: { step: 3, desc: 'Exchange in progress' },
	EXECUTION_COMPLETED: { step: 3, desc: 'Exchange in progress' },
	OUTFLOW_CALCULATED: { step: 3, desc: 'Exchange in progress' },
	PAYOUT_REQUESTED: { step: 3, desc: 'Exchange in progress' },
	CANCELLED: { step: 4, desc: 'Cancelled' },
	EXPIRED: { step: 4, desc: 'Expired' },
	COMPLETED: { step: 4, desc: 'Completed' },
}
const STEPS_COUNT = 4

export const TradeProgressSteps: FC<{ status: TradeStatus }> = memo(({ status }) => {
	const statusData = stepMap[status]

	return statusData ? (
		<Wrapper>
			<ProgressText>Progress:</ProgressText>
			<Progress percent={(statusData.step * 100) / STEPS_COUNT} steps={STEPS_COUNT} format={() => <Step>Step {statusData.step}/4</Step>} />
			<div>{statusData.desc}</div>
		</Wrapper>
	) : null
})
