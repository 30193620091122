import { UserOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { lazyComponent } from 'modules/helpers/lazyComponent'
import { themeColor } from 'modules/styles/themeStyles'
import { FC } from 'react'
import styled from 'styled-components'

const ClientProfileContent = lazyComponent(() => import('components/layout/header/menu/clientProfile/ClientProfileContent'))

const Wrapper = styled.div`
	cursor: pointer;
`
const User = styled.div`
	width: 40px;
	height: 40px;
	border: 2px solid ${themeColor.gray};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		fill: ${themeColor.gray};
		font-size: 25px;
	}
	&,
	svg {
		transition: all ease 0.5s;
	}
	&:hover {
		border-color: ${themeColor.blue};

		svg {
			fill: ${themeColor.blue};
		}
	}
`

export const ClientProfile: FC = () => {
	return (
		<Wrapper>
			<Popover
				content={
					<div style={{ width: '250px' }}>
						<ClientProfileContent />
					</div>
				}
				placement={'bottomRight'}
				trigger={'click'}
			>
				<User title={'Your profile'}>
					<UserOutlined />
				</User>
			</Popover>
		</Wrapper>
	)
}
