import { themeColor } from 'modules/styles/themeStyles'
import { css } from 'styled-components'

export const DefaultTableStyles = css`
	.ant-table {
		color: ${themeColor.gray};
	}
	// table header
	.ant-table-thead {
		th {
			color: ${themeColor.grayDarker};
		}
	}
	.ant-table-row > td {
		vertical-align: top;
	}
	// expanded row
	.ant-table-expanded-row > td {
		background: transparent !important;
	}
`

/**
 * remove border between parent & expanded row
 * set className in table: rowClassName={(r) => (getExpandedRowKeys().includes(r.id) ? 'with-expanded' : '')}
 */
export const RemoveBorderExpandedRow = css`
	.ant-table-row.with-expanded > td {
		border: none;
	}
`
