import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { EnabledCurrencies } from 'modules/helpers/currencies'
import { AsyncThunkActionType } from 'store/store'

export const currenciesGet: AsyncThunkActionType<EnabledCurrencies> = createAsyncThunk('currenciesGet', async (data, thunkAPI) => {
	const { rejectWithValue } = thunkAPI

	try {
		const response = await api.CurrencyFrontofficeController_findSupportedCurrencies(undefined, null, data?.config)

		return response.data
	} catch (err: any) {
		return rejectWithValue(err.response?.data?.message)
	}
})
