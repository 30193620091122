import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components, Paths } from 'api/fx/generated/client'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType } from 'store/store'

type PathParameters = Paths.TradeFrontofficeControllerFindTradeStatement.PathParameters
type StatementFrontofficeResponse = Components.Schemas.StatementFrontofficeResponse

type Returned = {
	id: PathParameters['tradeId']
	data: StatementFrontofficeResponse
}

export const statementGet: AsyncThunkActionType<Returned, AsyncThunkDataType<PathParameters, null, 'params'>> = createAsyncThunk(
	'statementGet',
	async ({ params, config }, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			const { data } = await api.TradeFrontofficeController_findTradeStatement(params, null, config)

			return {
				id: params.tradeId,
				data,
			} as Returned
		} catch (err: any) {
			return rejectWithValue(err?.response?.data?.message)
		}
	},
)
