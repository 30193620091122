import { PATH } from 'components/pages/Paths'
import { GenericRoute } from 'components/pages/routeHelpers/RestrictedRoute'
import { Redirect, Switch } from 'react-router-dom'
import { RoutePaths } from 'utils/setBreadcrumb'

const BASE_PATH = `/order`
export const TRADE_PATH: RoutePaths = {
	TRADE: { path: BASE_PATH, breadcrumb: 'Order' },
	CONFIRMATION: { path: `${BASE_PATH}/confirmation`, breadcrumb: 'Confirmation' },
	NEW_TRADE: { path: `${BASE_PATH}/new`, breadcrumb: 'New order' },
	HISTORY: { path: `${BASE_PATH}/history`, breadcrumb: 'History' },
}

const TradeRoutes = (
	<GenericRoute path={TRADE_PATH.TRADE.path} breadcrumbAction={'clear'} routePath={TRADE_PATH.TRADE} type={'private'}>
		<Switch>
			<GenericRoute
				path={TRADE_PATH.CONFIRMATION.path + '/:id'}
				routePath={TRADE_PATH.CONFIRMATION}
				type={'private'}
				lazyImport={() => import('components/pages/fx/trade/Confirmation')}
			/>
			<GenericRoute
				path={TRADE_PATH.NEW_TRADE.path}
				routePath={TRADE_PATH.NEW_TRADE}
				type={'private'}
				lazyImport={() => import('components/pages/fx/trade/NewTrade')}
			/>
			<GenericRoute
				path={TRADE_PATH.HISTORY.path}
				routePath={TRADE_PATH.HISTORY}
				type={'private'}
				lazyImport={() => import('components/pages/fx/trade/History')}
			/>
			<GenericRoute path={TRADE_PATH.TRADE.path} routePath={TRADE_PATH.TRADE} type={'private'}>
				<Redirect to={PATH.DASHBOARD.path} />
			</GenericRoute>
		</Switch>
	</GenericRoute>
)

export default TradeRoutes
