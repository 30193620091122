import { createSlice } from '@reduxjs/toolkit'
import { Components } from 'api/fx/generated/client'
import { statementGet } from 'store/asyncThunks/statementGet'

type StatementResponse = Components.Schemas.StatementFrontofficeResponse

type State = {
	ids: Record<string, StatementResponse> // key: trade id
}

const initialState: State = {
	ids: {},
}

export const statementSlice = createSlice<State, {}>({
	name: 'statement',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(statementGet.fulfilled, (state, action) => {
			const { id, data } = action.payload
			state.ids[id] = data
		})
	},
})

export default statementSlice.reducer
