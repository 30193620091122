import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { TradeResponse } from 'store/slices/tradesSlice'
import { AsyncThunkActionType } from 'store/store'

export const tradesGet: AsyncThunkActionType<TradeResponse[]> = createAsyncThunk('tradesGet', async (data, thunkAPI) => {
	const { rejectWithValue } = thunkAPI

	try {
		const response = await api.TradeFrontofficeController_findAllTrades(undefined, null, data?.config)

		return response.data as TradeResponse[]
	} catch (err: any) {
		return rejectWithValue(err.response?.data?.message)
	}
})
