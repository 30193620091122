import { RightCircleOutlined } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import { themeColor } from 'modules/styles/themeStyles'
import { FC } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	color: ${themeColor.blue};
`

// action button with icon & color
export const ActionButton: FC<ButtonProps> = ({ ...props }) => (
	<Wrapper>
		<Button type={'link'} icon={<RightCircleOutlined />} {...props} />
	</Wrapper>
)
