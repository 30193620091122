import { errorNetworkInterceptor } from 'api/common'
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { logoutAndClearState } from 'store/asyncThunks/logoutAndClearState'
import { setForbidden } from 'store/slices/accountSlice'
import { store } from 'store/store'

export const setInterceptors = (axios: AxiosInstance): void => {
	axios.interceptors.request.use(setHeaders, undefined)
	axios.interceptors.response.use(undefined, error401)
	axios.interceptors.response.use(undefined, error403)
	axios.interceptors.response.use(undefined, errorNetworkInterceptor)
}

const setHeaders = (req: AxiosRequestConfig) => {
	if (req && req.headers) {
		req.headers.Authorization = `Bearer ${store.getState().auth.session?.access_token}`
	}
	return req
}

const error401 = (error: AxiosError) => {
	const res = error.response
	if (res && res.status === 401) {
		store.dispatch(logoutAndClearState())
	}
	return Promise.reject(error)
}

const error403 = (error: AxiosError) => {
	const res = error.response
	if (res && res.status === 403) {
		store.dispatch(setForbidden(true))
	}
	return Promise.reject(error)
}
