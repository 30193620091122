import { MetaData } from 'components/functional/MetaData'
import { PATH } from 'components/pages/Paths'
import { useIsLoginPage } from 'hooks/useIsLoginPage'
import { useSelector } from 'hooks/useSelector'
import { lazyComponent, LazyComponentType } from 'modules/helpers/lazyComponent'
import { setBackToUri, useIsUserLogged } from 'modules/keycloak'
import { FC } from 'react'
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom'
import { dispatch } from 'store/store'
import { clearBreadcrumbPath, RoutePath, setBreadcrumbPath } from 'utils/setBreadcrumb'

interface IProps {
	lazyImport?: LazyComponentType<any>
	path: string
	routePath?: RoutePath
	type?: 'route' | 'private'
	breadcrumbAction?: 'clear' | 'set' | null
	redirectIfNotExact?: string
}

/**
 * @param children
 * @param lazyComponent
 * @param path
 * @param routePath
 * @param type
 * @param breadcrumbAction
 * @param redirectIfNotExact
 */
export const GenericRoute: FC<IProps> = ({
	children,
	lazyImport,
	path,
	routePath,
	type = 'route',
	breadcrumbAction = 'set',
	redirectIfNotExact,
}) => {
	// @ts-ignore
	let Component: FC<RouteProps> = Route
	if (type === 'private') {
		Component = PrivateRoute
	}

	// always set path, if 'clear' action then clear it before
	const onClearBreadcrumb = (): null => {
		breadcrumbAction === 'clear' && clearBreadcrumbPath()
		routePath && setBreadcrumbPath(routePath)

		return null
	}
	const Comp = lazyImport && lazyComponent(lazyImport)

	return (
		<Component
			path={path}
			render={(props) => {
				if (redirectIfNotExact && !props.match.isExact) {
					return <Redirect to={redirectIfNotExact} />
				}
				return (
					<>
						{/* clear meta data */}
						<MetaData />
						{onClearBreadcrumb()}
						{children}
						{Comp && <Comp />}
					</>
				)
			}}
		/>
	)
}

/**
 * If user is not logged, redirect to LOGIN_PAGE
 * Do not do that if client manually clicked logout action, to not redirect to auth
 */
export const RedirectConditions: FC = ({ children }) => {
	const isLoginPage = useIsLoginPage()
	const history = useHistory()
	const { isUserLogged, isUserLogoutPending } = useIsUserLogged()
	const { isForbidden } = useSelector((state) => state.account)

	if (!isUserLogged && !isLoginPage && !isUserLogoutPending) {
		const { pathname, search } = history.location
		dispatch(setBackToUri(`${pathname}${search ? `${search}` : ''}`))

		return <Redirect to={PATH.LOGIN.path} />
	}
	if (isForbidden) {
		return <Redirect to={PATH.ERROR_403.path} />
	}

	return <>{children}</>
}

export const PrivateRoute: FC<RouteProps> = ({ ...rest }) => (
	<>
		<RedirectConditions>
			<Route {...rest} />
		</RedirectConditions>
	</>
)
