import { Components } from 'api/fx/generated/client'
import dayjs from 'dayjs'
import { useSelector } from 'hooks/useSelector'
import { reduce } from 'lodash'
import { statusesInProgress } from 'modules/helpers/tradeStatuses'

type TradeFrontofficeResponse = Components.Schemas.TradeFrontofficeResponse

export interface ITradeRecord {
	id: string
	referenceNumber: string
	startAt: string
	inflow?: number // amount what customer put to the trade
	amountSeparator?: string
	outflow?: number
	actions?: undefined
}

export const getRecordItem = (item: TradeFrontofficeResponse): ITradeRecord => ({
	id: item.id || '',
	referenceNumber: item.referenceNumber || '',
	startAt: item.createdAt || '',
	inflow: item.inflow.amount,
	amountSeparator: '>',
	outflow: item.outflow?.payableAmount,
})

/**
 * Mapper data for table with status filtering
 */
export const useTradeInProgressData = (): ITradeRecord[] => {
	const { list, ids } = useSelector((state) => state.trades)

	// 24h ago
	const yesterday = dayjs().add(-1, 'd')

	return reduce<string, ITradeRecord[]>(
		list,
		(res, id) => {
			const val = ids[id]

			// show only inProgress orders or ended in the last 24h
			if ((val.status && ['NEW', ...statusesInProgress].includes(val.status)) || yesterday.isBefore(val.completedAt)) {
				res.push(getRecordItem(val))
			}
			return res
		},
		[],
	)
}
