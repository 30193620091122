import { AnyAction, createSlice } from '@reduxjs/toolkit'

type Status = 'pending' | 'rejected' | 'fulfilled'

type State = {
	[id: string]: {
		name: string
		status: Status
	}
}

const initialState: State = {}

const actionName = (action: AnyAction) => action.type.replace(`/${action.meta.requestStatus}`, '')

export const responseInfo = createSlice<State, {}>({
	name: 'responseInfo',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) => action.type.endsWith('/pending') || action.type.endsWith('/rejected') || action.type.endsWith('/fulfilled'),
			(state, action) => {
				state[action.meta.requestId] = {
					name: actionName(action),
					status: action.meta.requestStatus,
				}
			},
		)
	},
})

export default responseInfo.reducer
