import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserInfoResponse } from 'modules/keycloak/api/types'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType } from 'store/store'
import { getUserInfo as keycloakGetUserInfo, IUserInfoParams } from '../api/keycloakActions'

export const getUserInfo: AsyncThunkActionType<UserInfoResponse, AsyncThunkDataType<IUserInfoParams, null, 'params'>> = createAsyncThunk(
	'keycloak/getUserInfo',
	async ({ params }, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			const response = await keycloakGetUserInfo(params)

			return response.data
		} catch (err: any) {
			return rejectWithValue('')
		}
	},
)
