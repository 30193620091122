/**
 * hook to manual manage expanded rows
 * - only one row is expanded in the same time
 */
import { useState } from 'react'

export type IExpandReturned = {
	expandedRowKeys: string[]
	/**
	 * onExpand works as toggle function
	 */
	onExpand: (rowKey?: string) => (e) => void
}
export const useExpandRowTable = (): IExpandReturned => {
	const [rowKey, setRowKey] = useState<string | undefined>(undefined)

	const onExpand: IExpandReturned['onExpand'] = (key) => (e) => {
		e && e.preventDefault()

		// toggle key
		if (rowKey === key) {
			setRowKey(undefined)
		} else {
			setRowKey(key)
		}
	}

	return {
		expandedRowKeys: rowKey ? [rowKey] : [],
		onExpand,
	}
}
