import { Components } from 'api/fx/generated/client'

type TradeStatus = Components.Schemas.TradeStatus

export const statusClientFriendlyName: { [k in TradeStatus]: string } = {
	NEW: 'New',
	CANCELLED: 'Cancelled',
	EXPIRED: 'Expired',
	INFLOW_RECEIVED: 'Funds received',
	EXECUTION_REQUESTED: 'Exchange in progress',
	EXECUTION_COMPLETED: 'Exchange in progress',
	OUTFLOW_CALCULATED: 'Sending funds',
	PAYOUT_REQUESTED: 'Sending funds',
	COMPLETED: 'Completed',
}

export const statusAdminFriendlyName: { [k in TradeStatus]: string } = {
	...statusClientFriendlyName,
	EXECUTION_COMPLETED: 'Executed',
	OUTFLOW_CALCULATED: 'Ready to send',
	PAYOUT_REQUESTED: 'Sending funds',
}

/**
 * statuses on which long process can happen inflow_received -> payout_requested
 */
export const statusesLongProcess: TradeStatus[] = [
	'INFLOW_RECEIVED',
	'EXECUTION_REQUESTED',
	'EXECUTION_COMPLETED',
	'OUTFLOW_CALCULATED',
	'PAYOUT_REQUESTED',
]
export const statusesInProgress: TradeStatus[] = [
	'INFLOW_RECEIVED',
	'EXECUTION_REQUESTED',
	'EXECUTION_COMPLETED',
	'OUTFLOW_CALCULATED',
	'PAYOUT_REQUESTED',
]
export const statusesOutflowIsPartial: TradeStatus[] = ['NEW', 'INFLOW_RECEIVED', 'EXECUTION_REQUESTED', 'EXECUTION_COMPLETED']
export const statusesHistory: TradeStatus[] = ['EXPIRED', 'COMPLETED', 'CANCELLED']
export const statusesNotSuccessful: TradeStatus[] = ['EXPIRED', 'CANCELLED']
export const statusesExecuted: TradeStatus[] = ['EXECUTION_COMPLETED', 'OUTFLOW_CALCULATED', 'PAYOUT_REQUESTED', 'COMPLETED']
export const statusesAllowToCancel: TradeStatus[] = ['NEW']

// pending = waiting for system (trader, wsp, kraken ..)
export const statusesPending: TradeStatus[] = ['EXECUTION_REQUESTED', 'EXECUTION_COMPLETED', 'PAYOUT_REQUESTED']
