import axios from 'axios'
import { ObjectKeyType } from 'types'

type IEnv = {
	ENV: 'local' | 'development' | 'sandbox' | 'production'
	IS_PRODUCTION: boolean
} & ObjectKeyType<any>

export let ENV: IEnv = {
	ENV: 'local',
	IS_PRODUCTION: false,
	GTM_ID: '',
	ROLLBAR_TOKEN: '',
	FX_API_URL: '',
	KYC_STANDALONE_URL: '',
	KYC_API_URL: '',
	KEYCLOAK_DOMAIN: '',
	LANDING_PAGE_URL: '',
	/**
	 * domain where cookie (FX_LANDING_COOKIE_NAME) will be set
	 */
	LANDING_COOKIE_DOMAIN: '',
}

// Get environment variables
export const getEnv = (): Promise<void> => {
	return axios.get('/config.json').then(({ data }) => {
		ENV = {
			...data,
		}

		return Promise.resolve()
	})
}
