import { Alert } from 'antd'
import { AlertProps } from 'antd/lib/alert'
import { AxiosResponse } from 'axios'
import { isArray, isString } from 'lodash'
import { ERROR_MESSAGE } from 'modules/helpers/messageAsyncThunk'
import { themeColor, themeSize } from 'modules/styles/themeStyles'
import * as React from 'react'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

export const FORBIDDEN_MESSAGE = 'You do not have access to see this data.'

const AlertStyled = styled(Alert)`
	margin-bottom: 20px;
`
const Box = styled.div`
	color: ${themeColor.red};
	margin-left: 10px;
	font-size: ${themeSize.sm};
`
type MsgType = string | ReactNode

export interface IAsyncErrorMsgProps {
	showError?: boolean
	msg?: MsgType | MsgType[]
	type?: 'alert' | 'text'
	alertProps?: AlertProps
}
export const AsyncErrorMsg: FC<IAsyncErrorMsgProps> = ({ showError = true, msg, type = 'text', alertProps }) => {
	if (!msg) {
		return null
	}
	let errors: MsgType[] = []

	if (isArray(msg)) {
		errors = msg
	} else if (isString(msg) || React.isValidElement(msg)) {
		errors = [msg]
	} else {
		// show default msg if no correct data type
		errors = [ERROR_MESSAGE]
	}

	const items = errors.map((msg, index) => <Box key={index}>{msg}</Box>)

	if (showError) {
		if (type === 'text') {
			return <>{items}</>
		} else if (type === 'alert') {
			return <AlertStyled type={'error'} showIcon banner message={items} {...alertProps} />
		}
	}
	return null
}

type IAxiosErrorMsg = IAsyncErrorMsgProps & {
	respErr?: AxiosResponse
	msgByCode?: { [k in number]: string }
}
export const AxiosErrorMsg: FC<IAxiosErrorMsg> = ({ respErr, msgByCode = {}, ...props }) => {
	const status = respErr?.status
	let msg = (status && msgByCode[status]) || ''

	if (!msg) {
		if (status === 403) {
			msg = FORBIDDEN_MESSAGE
		}
	}

	return <AsyncErrorMsg msg={msg} type={'alert'} {...props} />
}
