import { Divider } from 'antd'
import { Bfx, LinkedIn } from 'assets/icon'
import { AddressBox } from 'components/layout/footer/AddressBox'
import { footerData } from 'components/layout/footer/footerData'
import { FooterLinks } from 'components/layout/footer/FooterLinks'
import { BRANCH, VERSION } from 'config/version'
import { DesktopView, MobileView } from 'modules/styles/mixins/MobileView'
import { themeColor, themeWeight } from 'modules/styles/themeStyles'
import { PageContent } from 'modules/ui/PageContent'
import { SpaceElement } from 'modules/ui/SpaceElement'
import { SuperAdminOnly } from 'modules/ui/SuperAdminOnly'
import { FC } from 'react'
import styled, { css } from 'styled-components'

const Desc = styled.div`
	margin: 0 0 20px 0;
`
const Left = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: 60px;
	${MobileView(css`
		margin-bottom: 0;
	`)}

	& > div {
		width: 50%;
		margin: 0 0 20px 0;
	}

	${DesktopView(
		css`
			flex-wrap: nowrap;

			& > div {
				width: auto;
			}
		`,
		{ screen: 'lg' },
	)}
	${DesktopView(
		css`
			& > div {
				width: 190px;
			}
		`,
		{ screen: 'xl' },
	)}
`
const Right = styled.div`
	width: 100%;
	max-width: 420px;
	margin-bottom: 60px;
	${MobileView(css`
		margin-bottom: 30px;
	`)}
`
const TopSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	padding: 20px 0;

	${DesktopView(
		css`
			align-items: flex-start;
			flex-direction: row;
		`,
		{ screen: 'xl' },
	)}
`
const ProductLogo = styled.div`
	height: 65px;

	svg {
		font-size: 4.5em;
	}
`

const Social = styled.div`
	font-weight: ${themeWeight.extraBold};

	svg {
		margin-top: 20px;
		color: ${themeColor.gray};
		font-size: 2em;
	}
`

const ColumnLinks = () => (
	<div>
		<ProductLogo />
		<FooterLinks links={footerData.links} />
	</div>
)
const ColumnSocial = () => (
	<div>
		<ProductLogo />
		<Social>
			Follow us!
			<br />
			<a href="https://www.linkedin.com/company/bitclear" target="_blank" rel="noreferrer">
				<LinkedIn />
			</a>
		</Social>
	</div>
)

const ColumnBfx = () => (
	<div>
		<ProductLogo>
			<a href={'/'}>
				<Bfx />
			</a>
		</ProductLogo>
		<FooterLinks links={footerData.bfx} />
	</div>
)

export const Footer: FC = () => {
	return (
		<PageContent width={'xl'}>
			<SpaceElement padding={'5%'} />
			<TopSection>
				<Left>
					<ColumnBfx />
					<div />
					<ColumnLinks />
					<ColumnSocial />
				</Left>
				<Right>
					<ProductLogo />
					<Desc dangerouslySetInnerHTML={{ __html: footerData.desc }} />
					<AddressBox withLogo />
				</Right>
			</TopSection>
			<Divider />
			<SpaceElement padding={'6%'} />
			<div>{footerData.copy}</div>
			<SuperAdminOnly>
				Version: {VERSION}
				<br />
				Branch: {BRANCH}
			</SuperAdminOnly>
			<SpaceElement padding={'6%'} />
		</PageContent>
	)
}
