import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components } from 'api/fx/generated/client'
import { walletsGet } from 'store/asyncThunks/walletsGet'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType, dispatch } from 'store/store'

type WalletResponse = Components.Schemas.WalletAddressResponse
export type CreateWalletRequest = Components.Schemas.CreateWalletRequest

export const walletCreate: AsyncThunkActionType<WalletResponse, AsyncThunkDataType<null, CreateWalletRequest, 'data'>> = createAsyncThunk(
	'walletCreate',
	async ({ data, config }, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			const response = await api.WalletFrontofficeController_create(undefined, data, config)

			dispatch(walletsGet({ config: { forceUpdate: true } }))

			return response.data as WalletResponse
		} catch (err: any) {
			return rejectWithValue(err.response?.data?.message)
		}
	},
)
