import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components, Paths } from 'api/fx/generated/client'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType } from 'store/store'

type TradeEventFrontofficeResponse = Components.Schemas.TradeEventFrontofficeResponse
type PathParameters = Paths.TradeFrontofficeControllerFindTradeHistory.PathParameters
type Returned = {
	id: PathParameters['tradeId']
	data: TradeEventFrontofficeResponse[]
}

export const tradeEventsGet: AsyncThunkActionType<Returned, AsyncThunkDataType<PathParameters, null, 'params'>> = createAsyncThunk(
	'tradeEventsGet',
	async ({ params, config }, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			const { data } = await api.TradeFrontofficeController_findTradeHistory(params, null, config)

			return {
				id: params.tradeId,
				data,
			} as Returned
		} catch (err: any) {
			return rejectWithValue(err.response?.data?.message)
		}
	},
)
