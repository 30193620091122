import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/kyc/api'
import { Components, Paths } from 'api/kyc/generated/client'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType } from 'store/store'

type CompanyResponse = Components.Schemas.CompanyResponse
type PathParameters = Paths.GetCompanyProfile.PathParameters

export const businessProfileGet: AsyncThunkActionType<
	CompanyResponse,
	AsyncThunkDataType<PathParameters, null, 'params'>
> = createAsyncThunk('businessProfileGet', async ({ params, config }, thunkAPI) => {
	const { rejectWithValue } = thunkAPI

	try {
		const response = await api.GetCompanyProfile(params, null, config)

		return response.data as CompanyResponse
	} catch (err: any) {
		return rejectWithValue(err.response?.data)
	}
})
