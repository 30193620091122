import { useSelector } from 'hooks/useSelector'

/**
 * check if token exist
 * when isLogoutPending, always return true to prevent redirection to the login page
 */

type IReturn = {
	isUserLogged: boolean
	isUserLogoutPending: boolean
}

export const useIsUserLogged = (): IReturn => {
	const { session, isUserLogoutPending } = useSelector((state) => state.auth)

	return {
		isUserLogged: !!(session && session.access_token),
		isUserLogoutPending,
	}
}
