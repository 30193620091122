import { FC } from 'react'
import DocumentMeta from 'react-document-meta'
import { ObjectKeyType } from 'types'

interface IProps {
	title?: string
	description?: string
	meta?: {
		charset?: string
		name?: ObjectKeyType<string>
	}
}
const initialProps: IProps = {
	title: '',
}

const getMeta = (data: IProps = initialProps): IProps => {
	const { title } = data
	return {
		title: `Bitclear AG - FX ${title}`,
		description: 'Trade with confidence. Your crypto exchange. Trade 24/7. Buy & Sell Bitcoin, and more with trust',
		meta: {
			charset: 'utf-8',
			name: {
				keywords: 'bitclear,bitcoin,cryptocurrency,payment provider,exchange,buy sell cryptocurrency,buy sell bitcoin',
			},
		},
	}
}

export const MetaData: FC<{ data?: IProps }> = ({ data }) => {
	return <DocumentMeta {...getMeta(data)} />
}
