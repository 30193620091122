import { MobileView } from 'modules/styles/mixins/MobileView'
import { IThemeScreen } from 'modules/styles/themeStyles'
import styled, { css } from 'styled-components'

interface ISpace {
	padding: string
	mobile?: {
		padding: string
		mobileScreen?: keyof IThemeScreen
	}
}

export const SpaceElement = styled.div<ISpace>`
	width: 100%;
	height: 0;
	padding: 0 0 ${({ padding }) => padding} 0;

	${({ mobile }) =>
		mobile &&
		MobileView(
			css`
				padding: 0 0 ${mobile.padding} 0;
			`,
			{ screen: mobile.mobileScreen || 'sm' },
		)}
`
