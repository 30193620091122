import { Components } from 'api/fx/generated/client'
import { themeColor } from 'modules/styles/themeStyles'

export type FiatCurrency = Components.Schemas.FiatCurrency
export type CryptoCurrency = Components.Schemas.CryptoCurrency
export type EnabledCurrencies = Components.Schemas.EnabledCurrenciesResponse
export type ACCEPTED_CURRENCY = Components.Schemas.CurrencyId

export const BTC_MAX_VALUE = 21000000
export const DEFAULT_PRECISION = 4
export const FIAT_RATE_DECIMAL_PRECISION = 4

/**
 * [normal color, light color]
 */
export const CRYPTO_COLOR = [themeColor.orange, themeColor.orangeMiddle]
export const FIAT_COLOR = [themeColor.blue, themeColor.blueMiddle]
