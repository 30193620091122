import { createSlice } from '@reduxjs/toolkit'
import { Components } from 'api/kyc/generated/client'
import { businessProfileGet } from 'store/asyncThunks/kyc/businessProfileGet'
import { individualProfileGet } from 'store/asyncThunks/kyc/individualProfileGet'
import { CaseReducerType } from 'store/store'

type IndividualProfileResponse = Components.Schemas.IndividualProfile
type CompanyResponse = Components.Schemas.CompanyResponse

type State = {
	individualProfile?: IndividualProfileResponse
	// business
	businessProfile?: CompanyResponse
}

const initialState: State = {}

type CaseReducers<State> = {
	clearKycState: CaseReducerType<State>
}

export const kycSlice = createSlice<State, CaseReducers<State>>({
	name: 'kyc',
	initialState,
	reducers: {
		clearKycState: () => ({ ...initialState }),
	},
	extraReducers: (builder) => {
		builder.addCase(individualProfileGet.fulfilled, (state, action) => {
			state.individualProfile = action.payload
		})
		builder.addCase(businessProfileGet.fulfilled, (state, action) => {
			state.businessProfile = action.payload
		})
	},
})

export const { clearKycState } = kycSlice.actions
export default kycSlice.reducer
