import AccountRoutes from 'components/pages/fx/account/AccountRoutes'
import Dashboard from 'components/pages/fx/Dashboard'
import TradeRoutes from 'components/pages/fx/trade/TradeRoutes'
import { PATH } from 'components/pages/Paths'
import { GenericRoute } from 'components/pages/routeHelpers/RestrictedRoute'
import { FC } from 'react'
import { Switch } from 'react-router-dom'

export const Routes: FC = () => {
	return (
		<Switch>
			{AccountRoutes}
			{TradeRoutes}
			<GenericRoute path={PATH.LOGIN.path + '/:redirect?'} breadcrumbAction={'clear'} lazyImport={() => import('components/pages/Login')} />
			<GenericRoute path={PATH.ERROR_403.path} breadcrumbAction={'clear'} lazyImport={() => import('components/pages/Error403')} />
			<GenericRoute path={PATH.REGISTER.path} breadcrumbAction={'clear'} lazyImport={() => import('components/pages/Register')} />
			<GenericRoute path={PATH.DASHBOARD.path} breadcrumbAction={'clear'} routePath={PATH.DASHBOARD} type={'private'}>
				<Switch>
					<GenericRoute path={PATH.DASHBOARD.path} routePath={PATH.DASHBOARD} type={'private'} redirectIfNotExact={PATH.DASHBOARD.path}>
						<Dashboard />
					</GenericRoute>
				</Switch>
			</GenericRoute>
		</Switch>
	)
}
