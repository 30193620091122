import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components } from 'api/fx/generated/client'
import { tradesGet } from 'store/asyncThunks/tradesGet'
import { AsyncThunkDataType } from 'store/common'
import { TradeResponse } from 'store/slices/tradesSlice'
import { AsyncThunkActionType, dispatch } from 'store/store'

type CreateTradeRequest = Components.Schemas.CreateTradeRequest

export const tradeCreate: AsyncThunkActionType<TradeResponse, AsyncThunkDataType<undefined, CreateTradeRequest, 'data'>> = createAsyncThunk(
	'tradeCreate',
	async ({ params, data, config }, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			const response = await api.TradeFrontofficeController_createNewTrade(params, data, config)

			dispatch(tradesGet({ config: { forceUpdate: true } }))

			return response.data as TradeResponse
		} catch (err: any) {
			return rejectWithValue(err.response?.data?.message)
		}
	},
)
