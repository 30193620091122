import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components, Paths } from 'api/fx/generated/client'
import { bankAccountsGet } from 'store/asyncThunks/bankAccountsGet'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType, dispatch } from 'store/store'

type BankAccountResponse = Components.Schemas.BankAccountResponse
type UpdateBankAccountRequest = Components.Schemas.UpdateBankAccountRequest
type PathParameters = Paths.BankAccountFrontofficeControllerUpdateBankAccountIfFieldsHaveChanged.PathParameters

export const bankAccountUpdate: AsyncThunkActionType<
	BankAccountResponse,
	AsyncThunkDataType<PathParameters, UpdateBankAccountRequest, 'data' | 'params'>
> = createAsyncThunk('bankAccountUpdate', async ({ params, data, config }, thunkAPI) => {
	const { rejectWithValue } = thunkAPI

	try {
		const response = await api.BankAccountFrontofficeController_updateBankAccountIfFieldsHaveChanged(params, data, config)

		dispatch(bankAccountsGet({ config: { forceUpdate: true } }))

		return response.data as BankAccountResponse
	} catch (err: any) {
		return rejectWithValue(err.response?.data?.message)
	}
})
