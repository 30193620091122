import 'antd/dist/reset.css'
import { antdGlobal } from 'modules/styles/antd/antdGlobal'
import 'modules/styles/font/poppins.css'
import { themeColor } from 'modules/styles/themeStyles'
import { createGlobalStyle, css } from 'styled-components'

const body = css`
	body {
		background-color: ${themeColor.blueMiddleWhite};
		line-height: 1.57;
	}
`

export const GlobalStyles = createGlobalStyle`
  ${body};
  ${antdGlobal};
`
