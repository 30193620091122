import { useSelector } from 'hooks/useSelector'
import { AsyncErrorMsg, IAsyncErrorMsgProps } from 'modules/ui/AsyncErrorMsg'
import { FC, useCallback, useState } from 'react'
import { HttpErrorMessage } from 'store/store'

export type ActionProto = { requestId: string; unwrap?: any; abort: Function }

export type ResponseInfo<Error = HttpErrorMessage | object> = {
	isPending: boolean
	isError: boolean
	error?: Error
	handleStatus: <T extends ActionProto>(action: T) => typeof action
	ErrorComponent: FC<IAsyncErrorMsgProps>
}

/**
 * get status action from responseInfo reducer
 *
 * @return error - data from response. AsyncThunk has to return it in rejectWithValue
 */
export const useActionStatus = <Error extends HttpErrorMessage | {}>(): ResponseInfo<Error> => {
	const [id, setId] = useState<string>('')
	const [error, setError] = useState<Error | undefined>(undefined)
	const { status } = useSelector((state) => state.responseInfo[id]) || {}
	const isError = status === 'rejected'

	const handleStatus = useCallback<ResponseInfo<Error>['handleStatus']>((action) => {
		setId(action.requestId)
		// clear error on every request
		error && setError(undefined)

		// handle error
		action.unwrap &&
			action.unwrap().catch((e: Error) => {
				e && setError(e)
				return e
			})

		return action
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // empty

	return {
		isPending: status === 'pending',
		isError,
		error,
		handleStatus,
		ErrorComponent: ({ ...props }) => (
			<AsyncErrorMsg msg={error} showError={isError} type={'alert'} alertProps={{ style: { marginTop: '5px' } }} {...props} />
		),
	}
}
