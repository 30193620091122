import { useState } from 'react'

export interface IModalReturn {
	showModal: boolean
	openModal: () => void
	closeModal: () => void
}
interface IProps {
	initialState?: boolean
}
export const useModalFn = (data?: IProps): IModalReturn => {
	const [showModal, setShowModal] = useState<boolean>(data?.initialState || false)

	return {
		showModal,
		openModal: () => setShowModal(true),
		closeModal: () => setShowModal(false),
	}
}
