import { FooterLink } from 'components/layout/footer/FooterLink'
import { MobileView } from 'modules/styles/mixins/MobileView'
import { FC } from 'react'
import styled, { css } from 'styled-components'

const Links = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 40px;

	> * {
		display: inline-block;
		margin-bottom: 20px;

		${MobileView(css`
			margin-bottom: 15px;
		`)}
	}
`

interface ILinks {
	label: string
	href: string
}

export const FooterLinks: FC<{ links: ILinks[] }> = ({ links }) => (
	<Links>
		{links.map((item, index) => (
			<FooterLink href={item.href} key={index} target="_self" rel="nofollow noreferrer">
				{item.label}
			</FooterLink>
		))}
	</Links>
)
