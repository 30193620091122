import { Components } from 'api/fx/generated/client'
import { Components as KycComponents } from 'api/kyc/generated/client'
import { useIsLoginPage } from 'hooks/useIsLoginPage'
import { useSelector } from 'hooks/useSelector'
import { CLIENT_STATUS, completed, getStatus } from 'modules/helpers/clientStatuses/clientStatuses'
import { useIsUserLogged } from 'modules/keycloak'
import { useCallback, useEffect, useState } from 'react'
import { accountGet } from 'store/asyncThunks/accountGet'
import { businessProfileGet } from 'store/asyncThunks/kyc/businessProfileGet'
import { individualProfileGet } from 'store/asyncThunks/kyc/individualProfileGet'
import { profilesGet } from 'store/asyncThunks/profilesGet'
import { dispatch } from 'store/store'

type ProfileResponse = Components.Schemas.ProfileFrontofficeResponse
type AccountFrontofficeResponse = Components.Schemas.AccountFrontofficeResponse
type CompanyResponse = KycComponents.Schemas.CompanyResponse
type IndividualProfile = KycComponents.Schemas.IndividualProfile

export interface IClientReturned {
	refreshData: () => void
	isPending: boolean
	isTradingBlocked: boolean
	isBusinessAccount: boolean
	account?: AccountFrontofficeResponse
	profile?: ProfileResponse
	userName: string
	companyName: string
	individualProfile?: IndividualProfile
	businessProfile?: CompanyResponse
	isKycActive: boolean
	isKycCompleted: boolean
}
export const useUserData = (withKyc?: boolean): IClientReturned => {
	const [isPending, setIsPending] = useState<boolean>(true)
	const { account, ids, profileId } = useSelector((state) => state.account)
	const profile = ids[profileId]
	const { individualProfile, businessProfile } = useSelector((state) => state.kyc)
	const isLoginPage = useIsLoginPage()
	// FIXME - isUserLogged not necessary here, but after remove it ReferenceError appears
	useIsUserLogged()
	const kycProfile = account?.kycProfile

	const getData = useCallback(() => {
		dispatch(accountGet())
			.unwrap()
			.then(() => {
				const promises: Promise<any>[] = [dispatch(profilesGet())]

				if (withKyc && account?.type && account?.kycProfile?.externalId) {
					if (account.type === 'PRIVATE') {
						promises.push(dispatch(individualProfileGet()))
					} else {
						promises.push(dispatch(businessProfileGet({ params: { companyId: account.kycProfile?.externalId } })))
						// KYC get logged user data (but how to know who he is?)
						// promises.push(dispatch(businessAuthPersonsGet({ params: { p1: account.kycProfile?.externalId } })))
					}
				}

				Promise.all(promises).finally(() => setIsPending(false))
			})
			.finally(() => setIsPending(false))
	}, [account?.kycProfile?.externalId, account?.type, withKyc])

	useEffect(() => {
		!isLoginPage && getData()
	}, [getData, isLoginPage])

	const getUserName = () => {
		if (account?.type === 'PRIVATE') {
			return (kycProfile?.firstName || '') + ' ' + (kycProfile?.lastName || '')
		} else {
			// KYC get logged user name
		}
	}

	return {
		refreshData: getData,
		isPending,
		account,
		profile,
		isTradingBlocked: !!account?.blocked,
		isBusinessAccount: account?.type === 'BUSINESS',
		userName: getUserName() || '',
		companyName: kycProfile?.companyName || '',
		individualProfile,
		businessProfile,
		isKycActive: !!kycProfile?.isActive,
		isKycCompleted: completed.includes(getStatus(kycProfile?.status as CLIENT_STATUS)),
	}
}
