import { themeColor, themeWeight } from 'modules/styles/themeStyles'
import React, { FC, MouseEventHandler, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { cyTag } from 'utils/cyTag'

const LinkStyled = styled(Link)`
	white-space: nowrap;
	color: ${themeColor.black};
	// font-weight: ${themeWeight.bold};
`
const Text = styled.span`
	text-decoration: underline;
	vertical-align: middle;
`
const ArrowStyled = styled.span<{ arrowToBottom?: boolean }>`
	vertical-align: middle;

	&:after {
		${({ arrowToBottom }) =>
			arrowToBottom
				? css`
						transform: rotate(90deg);
				  `
				: css``}
		content: '>';
		display: inline-block;
		margin: 0 0 0 6px;
		font-size: 1.2em;
	}
`

type IProps = {
	to?: string
	label?: string
	onClick?: MouseEventHandler<HTMLAnchorElement>
	arrowToBottom?: boolean
}
export const DetailsLink: FC<IProps> = ({ to = '', label = 'Details', onClick, arrowToBottom }): ReactElement => {
	return (
		<LinkStyled to={to} onClick={onClick} data-cy={cyTag.order.orderDetailsLink}>
			<Text>{label}</Text>
			<ArrowStyled arrowToBottom={arrowToBottom} />
		</LinkStyled>
	)
}
