import { Spin } from 'antd'
import { SpinProps } from 'antd/lib/spin'
import { FC } from 'react'
import styled from 'styled-components'

const SpinStyled = styled.div`
	& > .ant-spin.ant-spin-spinning {
		padding: 80px 0;
		width: 100%;
		text-align: center;
	}
`

/**
 * Centered spinner
 * @hideSpinningContent - hide content while spinning, by default content is visible
 */
type IProps = {
	hideSpinningContent?: boolean
} & SpinProps
export const SpinBox: FC<IProps> = ({ hideSpinningContent = false, children, spinning = true, ...props }) => {
	return (
		<SpinStyled>
			<Spin spinning={spinning} size={'default'} {...props}>
				{hideSpinningContent && spinning ? null : children}
			</Spin>
		</SpinStyled>
	)
}
