import apiSchema from 'api/kyc/generated/api.json'
import { Client } from 'api/kyc/generated/client'
import { setInterceptors } from 'api/kyc/interceptors'
import axios, { AxiosAdapter } from 'axios'
import { throttleAdapterEnhancer } from 'axios-extensions'
import { ENV } from 'config/env'
import { OpenAPIClientAxios } from 'openapi-client-axios'

export let api: Client

export const initKycApi = (): Promise<void> => {
	const openAPIClientAxios = new OpenAPIClientAxios({
		// @ts-ignore
		definition: apiSchema,
		strict: true,
		timeout: 20000,
		validate: true,
		withServer: { url: ENV.KYC_API_URL, description: 'KYC API' },
		axiosConfigDefaults: {
			headers: {
				'Content-Type': 'application/json',
			},
			adapter: throttleAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { threshold: 2 * 1000 }),
		},
	})

	return openAPIClientAxios.init<Client>().then((client) => {
		setInterceptors(openAPIClientAxios.client)

		api = client

		return Promise.resolve()
	})
}
