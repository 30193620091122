import auth, { AuthState } from 'modules/keycloak/store/authSlice'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import account from 'store/slices/accountSlice'
import bankAccounts from 'store/slices/bankAccountsSlice'
import breadcrumb from 'store/slices/breadcrumbSlice'
import currencies from 'store/slices/currenciesSlice'
import device from 'store/slices/deviceSlice'
import fxBankAccounts from 'store/slices/fxBankAccountsSlice'
import kyc from 'store/slices/kycSlice'
import responseInfo from 'store/slices/responseInfo'
import statement from 'store/slices/statementSlice'
import trades from 'store/slices/tradesSlice'
import wallets from 'store/slices/walletsSlice'

/**
 * clear whitelisted state in logoutAndClearState() action
 */
// account & kyc - to show data without jumping
export const whitelistPersist = ['account', 'kyc']

const authConfig = {
	key: 'auth',
	storage,
	whitelist: ['session', 'backToUri'] as (keyof AuthState)[],
}

const slices = {
	auth: persistReducer(authConfig, auth),
	account,
	kyc,
	wallets,
	bankAccounts,
	fxBankAccounts,
	responseInfo,
	breadcrumb,
	device,
	trades,
	currencies,
	statement,
}

export default slices
