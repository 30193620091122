import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components } from 'api/fx/generated/client'
import { AsyncThunkActionType } from 'store/store'

type AccountFrontofficeResponse = Components.Schemas.AccountFrontofficeResponse

export const accountGet: AsyncThunkActionType<AccountFrontofficeResponse> = createAsyncThunk('accountGet', async (data, thunkAPI) => {
	const { rejectWithValue } = thunkAPI

	try {
		const response = await api.AccountFrontofficeController_findAccount(undefined, null, data?.config)

		return response.data as AccountFrontofficeResponse
	} catch (err: any) {
		return rejectWithValue(err.response?.data?.message)
	}
})
