import { reduce, uniq } from 'lodash'

export type ListType = string[]

/**
 * group by id
 */
type Returned<ItemType> = {
	list: ListType
	ids: Record<string, ItemType>
}
export const listToEntity = <ItemType>(data: ItemType[], idAttr: keyof ItemType): Returned<ItemType> => ({
	list: data.map((item) => item[idAttr] as unknown as string),
	ids: reduce(
		data,
		(acc, v) => {
			acc[v[idAttr] as unknown as string] = v
			return acc
		},
		{},
	),
})

/**
 * group by id & nestedId
 *
 * @return
 * list: [id], // uniq list
 * ids: {
 *   id: {
 *     nestedId: {}
 *   }
 * }
 */
type ReturnedNested<ItemType> = {
	list: ListType
	ids: Record<string, Record<string, ItemType>>
}
export const listToEntityNested = <ItemType>(
	data: ItemType[],
	idAttr: keyof ItemType,
	nestedIdAttr: keyof ItemType = 'id' as keyof ItemType,
): ReturnedNested<ItemType> => ({
	list: uniq(data.map((item) => item[idAttr] as unknown as string)),
	ids: reduce(
		data,
		(acc, v) => {
			acc[v[idAttr] as unknown as string] = {
				...acc[v[idAttr] as unknown as string],
				[v[nestedIdAttr] as unknown as string]: v,
			}
			return acc
		},
		{},
	),
})
