import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components } from 'api/fx/generated/client'
import { AsyncThunkActionType } from 'store/store'

type AgreementStateFrontofficeResponse = Components.Schemas.AgreementStateFrontofficeResponse

export const agreementTocGet: AsyncThunkActionType<AgreementStateFrontofficeResponse> = createAsyncThunk(
	'agreementTocGet',
	async (data, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			const response = await api.TermsFrontofficeController_getAgreements(undefined, null, data?.config)

			return response.data as AgreementStateFrontofficeResponse
		} catch (err: any) {
			return rejectWithValue(err.response?.data?.message)
		}
	},
)
